// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { graphql, StaticQuery, Link } from "gatsby"
import scrollToElement from "scroll-to-element"


const _handleLinkClick = (e, target) => {

  // NODE-SAFE CODE
  // Gatsby uses Node to generate our pages. 
  // Node doesn't know what a window is. 
  // Be sure to wrap any of your browser interactions
  // in some sort of node-safe if statement like this:

  if (typeof window !== undefined) {

    // First, are we on the home page?
    // If so, let's scroll to the desired block,
    // which was passed in as an onClick prop on our Link.
    // An event was also passed, we'll preventDefault()

    if (window.location.pathname === '/') {
      e.preventDefault()
      scrollToElement(target, {
        offset: -40,
        duration: 1000,
      })
    }
  }
}

const Header = ({ siteTitle }) => (
  
  <StaticQuery
    query={graphql`
      query {
        image1: file(relativePath: { eq: "img/logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 100, maxHeight: 100) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    // render={data => <Lightbox carImages={data.allImages.edges} />}
    render={data => 
     
  <header
    className="container"
  >
    <div className="columns">
      <div className="column is-one-third flex">
        <Link to="/">
          <Img className="logo" fluid={data.image1.childImageSharp.fluid} />
        </Link>
      </div>
      <div className="column">
        <nav>
          <Link
            onClick={e => _handleLinkClick(e, '#projects')}
            to={'/#projects'}
              >Projects.&nbsp;&nbsp;&nbsp;&nbsp;</Link>
          <Link
            onClick={e => _handleLinkClick(e, '#about')}
            to={'/#about'}
              >About.&nbsp;&nbsp;&nbsp;&nbsp;</Link>
          <Link
            onClick={e => _handleLinkClick(e, '#contact')}
            to={'/#contact'}
              >Contact.&nbsp;&nbsp;&nbsp;&nbsp;</Link>
        </nav>
      </div>
    </div>
  </header>
    }
  />

)



Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
