// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { graphql, StaticQuery, Link } from "gatsby"



const Footer = () => (

  <StaticQuery
    query={graphql`
      query Footer {
        site {
          siteMetadata {
            title
          }
        }
        facebook: file(relativePath: { eq: "img/facebook.png" }) {
            childImageSharp {
              fixed(width:30) {
                ...GatsbyImageSharpFixed
            }
          }
        }
        insta: file(relativePath: { eq: "img/insta.png" }) {
            childImageSharp {
              fixed(width:30) {
                ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data =>

      <footer className="container">
        <div className="columns">
          <div className="column is-one-third">
            <p>E- handmade@contrasttimbers.co.uk<br />
              P- 0117 325 8202</p>
          </div>
          <div className="column is-one-third">
            <a href="https://www.facebook.com/contrasttimbers"><Img fixed={data.facebook.childImageSharp.fixed} /></a>&nbsp;
                  <a href="https://www.instagram.com/contrasttimbers/"><Img fixed={data.insta.childImageSharp.fixed} /></a>
          </div>
          <div className="column is-one-third">
            <p>unit 45 station road workshops<br />
              station road, kingswood,<br />
              Bristol, bs15 4pj</p>
          </div>
        </div>
        <p className="copy">© {new Date().getFullYear()} Contrast Timbers Furniture Limited. Company Number 12971665. Registered in United Kingdom</p>

      </footer>
    }
  />

)

export default Footer
